import React from 'react';

import { Layout } from '../components';

import './legal-returns-app.scss';

const LegalsEULA = () => (
  <Layout>
    <div className="wrapper">
      <div className="legal-returns-app">
        <h1 className="legal-returns-app__title">
          <strong>END USER LICENCE AGREEMENT</strong>
        </h1>
        <p>&nbsp;</p>
        <h3>Safedome Embedded Software End User License Agreement</h3>
        <p>&nbsp;</p>
        <p>
          Please read carefully this End User License Agreement (“EULA”) prior to ordering or using the Safedome card or
          using or downloading the software that is pre-installed or embedded on, or downloaded to, the Safedome card
          (“Embedded Software”). This is a legal agreement between you and Maxwell Forest. This EULA governs your access
          to, and use of, the Embedded Software. Any terms not defined in this agreement shall be given the same meaning
          as stated in the Master Terms of Service.
        </p>
        <p>
          By ordering the Safedome card, or downloading, installing, and/or using the Embedded Software, you acknowledge
          and agree that:
        </p>
        <ol className="legal-returns-app__ol--unindent">
          <li>
            <span>Your use of the Embedded Software in conjunction with the Safedome card is at your own risk;</span>
          </li>
          <li>
            <span>
              the Embedded Software is licensed to you, not sold, and you may only use the Embedded Software in the
              manner set forth in this EULA;
            </span>
          </li>
          <li>
            <span>the Embedded Software is provided “as is”.</span>
          </li>
        </ol>
        <p>
          If you do not agree to be bound by the above terms, described in further detail below, you may not order,
          download, install, and/or make use of the Embedded Software or Service.
        </p>

        <h6>1 LIMITED LICENSE</h6>
        <p>
          Subject to your compliance with the terms and conditions of this EULA, Maxwell Forest hereby grants you a
          limited, non-exclusive, personal, revocable, non-sublicensable, non-transferable, and non-assignable license
          to install and use the Embedded Software solely for your personal use on the Safedome card that is owned by
          you. Maxwell Forest reserves all rights in the Embedded Software not expressly granted in these terms.
        </p>

        <h6>2 USE RESTRICTIONS</h6>
        <p>You may not use the Embedded Software in any manner that could:</p>

        <ol className="legal-returns-app__ol--roman">
          <li> damage, disable, change, or otherwise impair the Embedded Software, or;</li>
          <li> interfere with any third party’s use and/or access to the Embedded Software.</li>
          <p>Furthermore, except as expressly specified in these terms, you may not:</p>
          <li>copy, modify or alter the Embedded Software;</li>
          <li>transfer, lease, sublicense, lend, rent or otherwise distribute the Embedded Software;</li>
          <li>use the Embedded Software in an unlawful manner or for any unlawful purpose;</li>
          <li>
            decompile, disassemble, or reverse engineer the components of the Embedded Software or Safedome card on
            which it is installed; or
          </li>
          <li>authorize or enable a third party to do any of the foregoing.</li>
        </ol>

        <h6>3 RESERVATION OF RIGHTS </h6>
        <p>
          The software, content, visual interface, interactive features, information, trademarks, logos, graphics,
          design, compilation, code, products, services, and all other elements of the Embedded Software (“Materials”)
          are protected by copyright, trade dress, patent, trade secret, and trademark laws of the United States and
          other jurisdictions, international conventions, and all other relevant intellectual property and proprietary
          rights, and applicable laws.
        </p>
        <p>
          All Materials, including intellectual property rights, are the property of Maxwell Forest and its subsidiaries
          or affiliated companies, including third-party licensors. You may not sell, license, distribute, copy, modify,
          publicly display, transmit, publish, edit, adapt or create derivative works from any Materials without the
          express written consent from Maxwell Forest.
        </p>
        <p>
          Maxwell Forest reserves all rights not expressly granted in these Terms. You shall not acquire any right,
          title or interest to any Materials whether by implication, estoppel, or otherwise, except for the limited
          rights set forth in these terms.
        </p>

        <h6>4 WARRANTY DISCLAIMER</h6>
        <p>
          THE EMBEDDED SOFTWARE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE EMBEDDED SOFTWARE ARE PROVIDED “AS
          IS” AND ON AN “AS AVAILABLE” BASIS, WITHOUT THE WARRANTY OR CONDITION OF ANY KIND, EITHER EXPRESSED OR
          IMPLIED. MAXWELL FOREST SPECIFICALLY (BUT WITHOUT LIMITATION) DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
          EXPRESSED OR IMPLIED, RELATING TO THE SERVICE, INCLUDING BUT NOT LIMITED TO:
        </p>
        <ol className="legal-returns-app__ol--roman">
          <li>
            ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR
            NON-INFRINGEMENT; AND
          </li>
          <li>ANY WARRANTIES ARISING OUT OF COURSE OF DEALING, USAGE OR TRADE.</li>
        </ol>
        <p>
          MAXWELL FOREST DOES NOT WARRANT THAT THE EMBEDDED SOFTWARE OR ANY PART THEREOF, OR ANY MATERIALS OR CONTENT
          OFFERED THROUGH THE EMBEDDED SOFTWARE, WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES OR OTHER
          HARMFUL COMPONENTS, AND DOES NOT WARRANT THAT ANY OF THE FOREGOING WILL BE CORRECTED.
        </p>
        <p>
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE EMBEDDED SOFTWARE OR ANY MATERIALS
          OR CONTENT AVAILABLE ON OR THROUGH THE EMBEDDED SOFTWARE WILL CREATE ANY WARRANTY REGARDING MAXWELL FOREST OR
          THE EMBEDDED SOFTWARE THAT IS NOT EXPRESSLY STATED IN THESE TERMS.
        </p>
        <p>
          ANY CONDITION OR WARRANTY WHICH WOULD BE IMPLIED BY LAW (INCLUDING BY THE SALE OF GOODS ACT 1923 (NSW) AND ANY
          SIMILAR AUSTRALIAN STATE BASED LEGISLATION) INTO THIS EULA IS EXCLUDED.
        </p>

        <h6>5 LIMITATION OF LIABILITY</h6>
        <h6 className="legal-returns-app__para-subtitle">5.1 GENERAL</h6>
        <p>
          UNDER NO CIRCUMSTANCES WILL MAXWELL FOREST BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL OR PUNITIVE LOSSES OR DAMAGES (INCLUDING, BUT NOT LIMITED TO, LOSS OF PROFITS, GOODWILL, USE,
          DATA OR OTHER INTANGIBLE LOSSES) ARISING OUT OF OR RELATING TO MAXWELL FOREST'S BREACH OF THIS EULA, YOUR
          ACCESS TO OR USE OF, OR INABILITY TO ACCESS OR USE, THE EMBEDDED SOFTWARE OR ANY MATERIALS OR CONTENT ON THE
          SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE OR ANY OTHER LEGAL THEORY,
          WHETHER OR NOT MAXWELL FOREST WAS AWARE OR HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE.
        </p>
        <p>
          YOU AGREE THAT THE AGGREGATE LIABILITY OF MAXWELL FOREST TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF RELATING
          TO THE USE OF OR ANY INABILITY TO USE THE EMBEDDED SOFTWARE (INCLUDING ANY MATERIALS OR CONTENT AVAILABLE
          THROUGH THE EMBEDDED SOFTWARE) OR OTHERWISE UNDER THIS EULA, THE LIMITED WARRANTY, AND THE MASTER TERMS OF
          SERVICE, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, IS LIMITED TO THE GREATER OF:
        </p>

        <ol className="legal-returns-app__ol--roman">
          <li>
            THE AMOUNTS YOU HAVE PAID TO MAXWELL FOREST FOR ACCESS TO, AND USE OF, THE SERVICE IN THE 12 MONTHS PRIOR TO
            THE CLAIM; AND
          </li>
          <li>FIVE DOLLARS ($5 US)</li>
        </ol>

        <p>
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
          DAMAGES. ACCORDINGLY, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
        </p>
        <p>
          THE UNITED NATIONS CONVENTION ON CONTRACTS FOR THE INTERNATIONAL SALE OF GOODS IS EXPRESSLY EXCLUDED AND WILL
          NOT APPLY TO THESE TERMS (INCLUDING THE ADDITIONAL TERMS).
        </p>

        <h6 className="legal-returns-app__para-subtitle">5.2 AUSTRALIAN RESIDENT CONSUMERS</h6>
        <p>Notwithstanding any other provision of this EULA, if you are an Australian resident Consumer (as defined in Schedule 2 of the Competition and Consumer Act 2010 (Cth) and the corresponding provisions of the Australian Consumer Law (New South Wales) (“Australian Consumer Law”)):</p>

        <p className="legal-returns-app__para--indent--1">
          (1) all exclusions or limitations of liability under this EULA do not apply to any liability of Maxwell Forest to fail to comply with an applicable consumer guarantee under the Australian Consumer Law;
        </p>
        <p className="legal-returns-app__para--indent--1">
          (2) subject to paragraph (3) below, the liability of Maxwell Forest for any liability, loss, cost or damage, however caused (including by the negligence of Maxwell Forest), suffered or incurred by you because of a failure to comply with a consumer guarantee is limited for Maxwell Forest (at its election):
        </p>

        <p className="legal-returns-app__para--indent--2">(A) for services supplied under this EULA, to the cost of:</p>
        <ul>
          <li className="legal-returns-app__para--indent--2">
            resupplying the services; or
          </li>
          <li className="legal-returns-app__para--indent--2">
            paying the cost of having the services supplied again; or
          </li>
        </ul>

        <p className="legal-returns-app__para--indent--2">(B) for goods supplied under this EULA, to the cost of:</p>
        <ul>
          <li className="legal-returns-app__para--indent--2">
            repairing or replacing the goods;
          </li>
          <li className="legal-returns-app__para--indent--2">
            supplying equivalent goods; or
          </li>
          <li className="legal-returns-app__para--indent--2">
            paying the cost to have the goods replaced or repaired.
          </li>
        </ul>

        <p className="legal-returns-app__para--indent--1">
          (3) Section 5.2 (2) does not apply if it is not fair or reasonable for Maxwell Forest to rely on it for the purposes of section 64A of the Australian Consumer Law.
        </p>

        <h6>6 INDEMNIFICATION</h6>
        <p>
          You agree that you will be responsible for your use of the Embedded Software, and you agree to defend,
          indemnify, and hold harmless Maxwell Forest and its officers, directors, employees, consultants, affiliates,
          subsidiaries and agents (collectively “Maxwell Forest entities”) from and against any and all claims,
          liabilities, damages, losses, and expenses, including reasonable attorney’s fees and costs arising out of or
          in any way connected with any breach by you of this EULA.
        </p>

        <h6>7 GENERAL PROVISIONS</h6>
        <h6 className="legal-returns-app__para-subtitle">7.1 Entire Agreement</h6>
        <p>
          This EULA and the Master Terms of Service constitutes the entire agreement between you and Maxwell Forest
          regarding your use of the Embedded Software, and supersedes all prior and contemporaneous agreements,
          proposals, representations, negotiations or other communications - written or oral - between you and Maxwell
          Forest, with respect to the subject matter. No modification, amendment, or waiver of any provision of this
          EULA will be effective unless in writing and signed by the party against whom the modification, amendment or
          waiver is to be asserted.
        </p>

        <h6 className="legal-returns-app__para-subtitle">7.2 Term and Termination</h6>
        <p>
          This EULA will commence upon shipment of the Safedome card to you and will remain in effect for the Service
          Term (as defined in the Master Terms of Service), unless earlier terminated in accordance with this Section
          7.2. The EULA, and all associated licenses, will terminate:
        </p>

        <ol className="legal-returns-app__ol--roman">
          <li>if you breach the EULA at any point;</li>
          <li>if you uninstall or discontinue your use of the Embedded Software;</li>
          <li>
            subject to the refund provisions set forth in Section 3.3 of the Safedome Master Terms of Service at any
            time upon the discretion of Maxwell Forest.
          </li>
        </ol>

        <p>
          Sections of this EULA entitled Reservation of Rights, Use Restrictions, Term and Termination, Warranty
          Disclaimer, Limitation of Liability, Indemnification and General Provisions shall survive the termination of
          this EULA.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>Last updated: September 4, 2015</p>
      </div>
    </div>
  </Layout>
);

export default LegalsEULA;
